import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './package-subscription.reducer';

export const PackageSubscriptionDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const packageSubscriptionEntity = useAppSelector(state => state.packageSubscription.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="packageSubscriptionDetailsHeading">
          <Translate contentKey="tinyMedicRestApp.packageSubscription.detail.title">PackageSubscription</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionEntity.id}</dd>
          <dt>
            <span id="validFrom">
              <Translate contentKey="tinyMedicRestApp.packageSubscription.validFrom">Valid From</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionEntity.validFrom}</dd>
          <dt>
            <span id="validTill">
              <Translate contentKey="tinyMedicRestApp.packageSubscription.validTill">Valid Till</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionEntity.validTill}</dd>
          <dt>
            <span id="remindFrom">
              <Translate contentKey="tinyMedicRestApp.packageSubscription.remindFrom">Remind From</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionEntity.remindFrom}</dd>
          <dt>
            <span id="lockOutOn">
              <Translate contentKey="tinyMedicRestApp.packageSubscription.lockOutOn">Lock Out On</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionEntity.lockOutOn}</dd>
          <dt>
            <span id="paidAmount">
              <Translate contentKey="tinyMedicRestApp.packageSubscription.paidAmount">Paid Amount</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionEntity.paidAmount}</dd>
          <dt>
            <span id="pendingAmount">
              <Translate contentKey="tinyMedicRestApp.packageSubscription.pendingAmount">Pending Amount</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionEntity.pendingAmount}</dd>
          <dt>
            <span id="expired">
              <Translate contentKey="tinyMedicRestApp.packageSubscription.expired">Expired</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionEntity.expired ? 'true' : 'false'}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="tinyMedicRestApp.packageSubscription.active">Active</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="lastUpdated">
              <Translate contentKey="tinyMedicRestApp.packageSubscription.lastUpdated">Last Updated</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionEntity.lastUpdated}</dd>
          <dt>
            <span id="microSec">
              <Translate contentKey="tinyMedicRestApp.packageSubscription.microSec">Micro Sec</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionEntity.microSec}</dd>
          <dt>
            <span id="timestampEntry">
              <Translate contentKey="tinyMedicRestApp.packageSubscription.timestampEntry">Timestamp Entry</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionEntity.timestampEntry}</dd>
          <dt>
            <span id="cursor">
              <Translate contentKey="tinyMedicRestApp.packageSubscription.cursor">Cursor</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionEntity.cursor}</dd>
          <dt>
            <span id="subscriptionNumber">
              <Translate contentKey="tinyMedicRestApp.packageSubscription.subscriptionNumber">Subscription Number</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionEntity.subscriptionNumber}</dd>
          <dt>
            <span id="suspended">
              <Translate contentKey="tinyMedicRestApp.packageSubscription.suspended">Suspended</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionEntity.suspended ? 'true' : 'false'}</dd>
          <dt>
            <span id="suspendedReason">
              <Translate contentKey="tinyMedicRestApp.packageSubscription.suspendedReason">Suspended Reason</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionEntity.suspendedReason}</dd>
          <dt>
            <span id="suspendedDate">
              <Translate contentKey="tinyMedicRestApp.packageSubscription.suspendedDate">Suspended Date</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionEntity.suspendedDate}</dd>
          <dt>
            <span id="suspendedBy">
              <Translate contentKey="tinyMedicRestApp.packageSubscription.suspendedBy">Suspended By</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionEntity.suspendedBy}</dd>
          <dt>
            <span id="paymentStatus">
              <Translate contentKey="tinyMedicRestApp.packageSubscription.paymentStatus">Payment Status</Translate>
            </span>
          </dt>
          <dd>{packageSubscriptionEntity.paymentStatus}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.packageSubscription.scheme">Scheme</Translate>
          </dt>
          <dd>{packageSubscriptionEntity.scheme ? packageSubscriptionEntity.scheme.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.packageSubscription.billToAddress">Bill To Address</Translate>
          </dt>
          <dd>{packageSubscriptionEntity.billToAddress ? packageSubscriptionEntity.billToAddress.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/package-subscription" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/package-subscription/${packageSubscriptionEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PackageSubscriptionDetail;
