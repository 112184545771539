import { IPackageSubscription } from 'app/shared/model/package-subscription.model';
import { IGlucoUser } from 'app/shared/model/gluco-user.model';

export interface IPackagePaymentHistory {
  id?: string;
  paymentMethod?: string | null;
  transactionDetails?: string | null;
  paidAmount?: number | null;
  active?: boolean | null;
  cursor?: number;
  timestampentry?: number | null;
  microSec?: number | null;
  lastupdated?: number | null;
  paidByFirstName?: string | null;
  paidBySecondName?: string | null;
  subscription?: IPackageSubscription | null;
  paidBy?: IGlucoUser | null;
}

export const defaultValue: Readonly<IPackagePaymentHistory> = {
  active: false,
};
