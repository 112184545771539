import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './blood-bank-inventory.reducer';

export const BloodBankInventoryDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const bloodBankInventoryEntity = useAppSelector(state => state.bloodBankInventory.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="bloodBankInventoryDetailsHeading">
          <Translate contentKey="tinyMedicRestApp.bloodBankInventory.detail.title">BloodBankInventory</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="tinyMedicRestApp.bloodBankInventory.id">Id</Translate>
            </span>
          </dt>
          <dd>{bloodBankInventoryEntity.id}</dd>
          <dt>
            <span id="bloodBagNumber">
              <Translate contentKey="tinyMedicRestApp.bloodBankInventory.bloodBagNumber">Blood Bag Number</Translate>
            </span>
          </dt>
          <dd>{bloodBankInventoryEntity.bloodBagNumber}</dd>
          <dt>
            <span id="bloodType">
              <Translate contentKey="tinyMedicRestApp.bloodBankInventory.bloodType">Blood Type</Translate>
            </span>
          </dt>
          <dd>{bloodBankInventoryEntity.bloodType}</dd>
          <dt>
            <span id="rhType">
              <Translate contentKey="tinyMedicRestApp.bloodBankInventory.rhType">Rh Type</Translate>
            </span>
          </dt>
          <dd>{bloodBankInventoryEntity.rhType}</dd>
          <dt>
            <span id="productType">
              <Translate contentKey="tinyMedicRestApp.bloodBankInventory.productType">Product Type</Translate>
            </span>
          </dt>
          <dd>{bloodBankInventoryEntity.productType}</dd>
          <dt>
            <span id="collectionDate">
              <Translate contentKey="tinyMedicRestApp.bloodBankInventory.collectionDate">Collection Date</Translate>
            </span>
          </dt>
          <dd>{bloodBankInventoryEntity.collectionDate}</dd>
          <dt>
            <span id="expiryDate">
              <Translate contentKey="tinyMedicRestApp.bloodBankInventory.expiryDate">Expiry Date</Translate>
            </span>
          </dt>
          <dd>{bloodBankInventoryEntity.expiryDate}</dd>
          <dt>
            <span id="storageLocation">
              <Translate contentKey="tinyMedicRestApp.bloodBankInventory.storageLocation">Storage Location</Translate>
            </span>
          </dt>
          <dd>{bloodBankInventoryEntity.storageLocation}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="tinyMedicRestApp.bloodBankInventory.active">Active</Translate>
            </span>
          </dt>
          <dd>{bloodBankInventoryEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="cursor">
              <Translate contentKey="tinyMedicRestApp.bloodBankInventory.cursor">Cursor</Translate>
            </span>
          </dt>
          <dd>{bloodBankInventoryEntity.cursor}</dd>
          <dt>
            <span id="lastUpdated">
              <Translate contentKey="tinyMedicRestApp.bloodBankInventory.lastUpdated">Last Updated</Translate>
            </span>
          </dt>
          <dd>{bloodBankInventoryEntity.lastUpdated}</dd>
          <dt>
            <span id="microSec">
              <Translate contentKey="tinyMedicRestApp.bloodBankInventory.microSec">Micro Sec</Translate>
            </span>
          </dt>
          <dd>{bloodBankInventoryEntity.microSec}</dd>
          <dt>
            <span id="timestampEntry">
              <Translate contentKey="tinyMedicRestApp.bloodBankInventory.timestampEntry">Timestamp Entry</Translate>
            </span>
          </dt>
          <dd>{bloodBankInventoryEntity.timestampEntry}</dd>
          <dt>
            <span id="volume">
              <Translate contentKey="tinyMedicRestApp.bloodBankInventory.volume">Volume</Translate>
            </span>
          </dt>
          <dd>{bloodBankInventoryEntity.volume}</dd>
          <dt>
            <span id="notes">
              <Translate contentKey="tinyMedicRestApp.bloodBankInventory.notes">Notes</Translate>
            </span>
          </dt>
          <dd>{bloodBankInventoryEntity.notes}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.bloodBankInventory.validator">Validator</Translate>
          </dt>
          <dd>{bloodBankInventoryEntity.validator ? bloodBankInventoryEntity.validator.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.bloodBankInventory.bank">Bank</Translate>
          </dt>
          <dd>{bloodBankInventoryEntity.bank ? bloodBankInventoryEntity.bank.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.bloodBankInventory.donation">Donation</Translate>
          </dt>
          <dd>{bloodBankInventoryEntity.donation ? bloodBankInventoryEntity.donation.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.bloodBankInventory.status">Status</Translate>
          </dt>
          <dd>{bloodBankInventoryEntity.status ? bloodBankInventoryEntity.status.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.bloodBankInventory.productComponent">Product Component</Translate>
          </dt>
          <dd>{bloodBankInventoryEntity.productComponent ? bloodBankInventoryEntity.productComponent.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.bloodBankInventory.donationApproval">Donation Approval</Translate>
          </dt>
          <dd>{bloodBankInventoryEntity.donationApproval ? bloodBankInventoryEntity.donationApproval.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/blood-bank-inventory" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/blood-bank-inventory/${bloodBankInventoryEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default BloodBankInventoryDetail;
