import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './diag-lab-panel-item.reducer';

export const DiagLabPanelItemDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const diagLabPanelItemEntity = useAppSelector(state => state.diagLabPanelItem.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="diagLabPanelItemDetailsHeading">
          <Translate contentKey="tinyMedicRestApp.diagLabPanelItem.detail.title">DiagLabPanelItem</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="tinyMedicRestApp.diagLabPanelItem.id">Id</Translate>
            </span>
          </dt>
          <dd>{diagLabPanelItemEntity.id}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="tinyMedicRestApp.diagLabPanelItem.active">Active</Translate>
            </span>
          </dt>
          <dd>{diagLabPanelItemEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="lastupdated">
              <Translate contentKey="tinyMedicRestApp.diagLabPanelItem.lastupdated">Lastupdated</Translate>
            </span>
          </dt>
          <dd>{diagLabPanelItemEntity.lastupdated}</dd>
          <dt>
            <span id="microSec">
              <Translate contentKey="tinyMedicRestApp.diagLabPanelItem.microSec">Micro Sec</Translate>
            </span>
          </dt>
          <dd>{diagLabPanelItemEntity.microSec}</dd>
          <dt>
            <span id="timestampentry">
              <Translate contentKey="tinyMedicRestApp.diagLabPanelItem.timestampentry">Timestampentry</Translate>
            </span>
          </dt>
          <dd>{diagLabPanelItemEntity.timestampentry}</dd>
          <dt>
            <span id="cursor">
              <Translate contentKey="tinyMedicRestApp.diagLabPanelItem.cursor">Cursor</Translate>
            </span>
          </dt>
          <dd>{diagLabPanelItemEntity.cursor}</dd>
          <dt>
            <span id="sortOrder">
              <Translate contentKey="tinyMedicRestApp.diagLabPanelItem.sortOrder">Sort Order</Translate>
            </span>
          </dt>
          <dd>{diagLabPanelItemEntity.sortOrder}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.diagLabPanelItem.lcTestItem">Lc Test Item</Translate>
          </dt>
          <dd>{diagLabPanelItemEntity.lcTestItem ? diagLabPanelItemEntity.lcTestItem.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.diagLabPanelItem.panel">Panel</Translate>
          </dt>
          <dd>{diagLabPanelItemEntity.panel ? diagLabPanelItemEntity.panel.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.diagLabPanelItem.childPanel">Child Panel</Translate>
          </dt>
          <dd>{diagLabPanelItemEntity.childPanel ? diagLabPanelItemEntity.childPanel.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/diag-lab-panel-item" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/diag-lab-panel-item/${diagLabPanelItemEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default DiagLabPanelItemDetail;
