import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import AdmissionEMContacts from './admission-em-contacts';
import AdmissionEMContactsDetail from './admission-em-contacts-detail';
import AdmissionEMContactsUpdate from './admission-em-contacts-update';
import AdmissionEMContactsDeleteDialog from './admission-em-contacts-delete-dialog';

const AdmissionEMContactsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<AdmissionEMContacts />} />
    <Route path="new" element={<AdmissionEMContactsUpdate />} />
    <Route path=":id">
      <Route index element={<AdmissionEMContactsDetail />} />
      <Route path="edit" element={<AdmissionEMContactsUpdate />} />
      <Route path="delete" element={<AdmissionEMContactsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default AdmissionEMContactsRoutes;
