import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './blood-donation.reducer';

export const BloodDonationDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const bloodDonationEntity = useAppSelector(state => state.bloodDonation.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="bloodDonationDetailsHeading">
          <Translate contentKey="tinyMedicRestApp.bloodDonation.detail.title">BloodDonation</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="tinyMedicRestApp.bloodDonation.id">Id</Translate>
            </span>
          </dt>
          <dd>{bloodDonationEntity.id}</dd>
          <dt>
            <span id="baggedDate">
              <Translate contentKey="tinyMedicRestApp.bloodDonation.baggedDate">Bagged Date</Translate>
            </span>
          </dt>
          <dd>{bloodDonationEntity.baggedDate}</dd>
          <dt>
            <span id="bagNo">
              <Translate contentKey="tinyMedicRestApp.bloodDonation.bagNo">Bag No</Translate>
            </span>
          </dt>
          <dd>{bloodDonationEntity.bagNo}</dd>
          <dt>
            <span id="consentDate">
              <Translate contentKey="tinyMedicRestApp.bloodDonation.consentDate">Consent Date</Translate>
            </span>
          </dt>
          <dd>{bloodDonationEntity.consentDate}</dd>
          <dt>
            <span id="consentNo">
              <Translate contentKey="tinyMedicRestApp.bloodDonation.consentNo">Consent No</Translate>
            </span>
          </dt>
          <dd>{bloodDonationEntity.consentNo}</dd>
          <dt>
            <span id="bloodType">
              <Translate contentKey="tinyMedicRestApp.bloodDonation.bloodType">Blood Type</Translate>
            </span>
          </dt>
          <dd>{bloodDonationEntity.bloodType}</dd>
          <dt>
            <span id="rhType">
              <Translate contentKey="tinyMedicRestApp.bloodDonation.rhType">Rh Type</Translate>
            </span>
          </dt>
          <dd>{bloodDonationEntity.rhType}</dd>
          <dt>
            <span id="tubeID">
              <Translate contentKey="tinyMedicRestApp.bloodDonation.tubeID">Tube ID</Translate>
            </span>
          </dt>
          <dd>{bloodDonationEntity.tubeID}</dd>
          <dt>
            <span id="collectDate">
              <Translate contentKey="tinyMedicRestApp.bloodDonation.collectDate">Collect Date</Translate>
            </span>
          </dt>
          <dd>{bloodDonationEntity.collectDate}</dd>
          <dt>
            <span id="startTime">
              <Translate contentKey="tinyMedicRestApp.bloodDonation.startTime">Start Time</Translate>
            </span>
          </dt>
          <dd>{bloodDonationEntity.startTime}</dd>
          <dt>
            <span id="endTime">
              <Translate contentKey="tinyMedicRestApp.bloodDonation.endTime">End Time</Translate>
            </span>
          </dt>
          <dd>{bloodDonationEntity.endTime}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="tinyMedicRestApp.bloodDonation.active">Active</Translate>
            </span>
          </dt>
          <dd>{bloodDonationEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="cursor">
              <Translate contentKey="tinyMedicRestApp.bloodDonation.cursor">Cursor</Translate>
            </span>
          </dt>
          <dd>{bloodDonationEntity.cursor}</dd>
          <dt>
            <span id="lastUpdated">
              <Translate contentKey="tinyMedicRestApp.bloodDonation.lastUpdated">Last Updated</Translate>
            </span>
          </dt>
          <dd>{bloodDonationEntity.lastUpdated}</dd>
          <dt>
            <span id="microSec">
              <Translate contentKey="tinyMedicRestApp.bloodDonation.microSec">Micro Sec</Translate>
            </span>
          </dt>
          <dd>{bloodDonationEntity.microSec}</dd>
          <dt>
            <span id="timestampEntry">
              <Translate contentKey="tinyMedicRestApp.bloodDonation.timestampEntry">Timestamp Entry</Translate>
            </span>
          </dt>
          <dd>{bloodDonationEntity.timestampEntry}</dd>
          <dt>
            <span id="extracted">
              <Translate contentKey="tinyMedicRestApp.bloodDonation.extracted">Extracted</Translate>
            </span>
          </dt>
          <dd>{bloodDonationEntity.extracted ? 'true' : 'false'}</dd>
          <dt>
            <span id="extractedAt">
              <Translate contentKey="tinyMedicRestApp.bloodDonation.extractedAt">Extracted At</Translate>
            </span>
          </dt>
          <dd>{bloodDonationEntity.extractedAt}</dd>
          <dt>
            <span id="notes">
              <Translate contentKey="tinyMedicRestApp.bloodDonation.notes">Notes</Translate>
            </span>
          </dt>
          <dd>{bloodDonationEntity.notes}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.bloodDonation.donor">Donor</Translate>
          </dt>
          <dd>{bloodDonationEntity.donor ? bloodDonationEntity.donor.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.bloodDonation.donationType">Donation Type</Translate>
          </dt>
          <dd>{bloodDonationEntity.donationType ? bloodDonationEntity.donationType.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.bloodDonation.bagType">Bag Type</Translate>
          </dt>
          <dd>{bloodDonationEntity.bagType ? bloodDonationEntity.bagType.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.bloodDonation.approval">Approval</Translate>
          </dt>
          <dd>{bloodDonationEntity.approval ? bloodDonationEntity.approval.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.bloodDonation.fitness">Fitness</Translate>
          </dt>
          <dd>{bloodDonationEntity.fitness ? bloodDonationEntity.fitness.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.bloodDonation.bank">Bank</Translate>
          </dt>
          <dd>{bloodDonationEntity.bank ? bloodDonationEntity.bank.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.bloodDonation.collectedCamp">Collected Camp</Translate>
          </dt>
          <dd>{bloodDonationEntity.collectedCamp ? bloodDonationEntity.collectedCamp.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/blood-donation" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/blood-donation/${bloodDonationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default BloodDonationDetail;
