import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import DiagLabPanelItem from './diag-lab-panel-item';
import DiagLabPanelItemDetail from './diag-lab-panel-item-detail';
import DiagLabPanelItemUpdate from './diag-lab-panel-item-update';
import DiagLabPanelItemDeleteDialog from './diag-lab-panel-item-delete-dialog';

const DiagLabPanelItemRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<DiagLabPanelItem />} />
    <Route path="new" element={<DiagLabPanelItemUpdate />} />
    <Route path=":id">
      <Route index element={<DiagLabPanelItemDetail />} />
      <Route path="edit" element={<DiagLabPanelItemUpdate />} />
      <Route path="delete" element={<DiagLabPanelItemDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default DiagLabPanelItemRoutes;
