import { IPaymentScheme } from 'app/shared/model/payment-scheme.model';
import { IMedicine } from 'app/shared/model/medicine.model';
import { IConsumable } from 'app/shared/model/consumable.model';
import { IImplant } from 'app/shared/model/implant.model';
import { HikeType } from 'app/shared/model/enumerations/hike-type.model';

export interface IPackageMedicine {
  id?: string;
  active?: boolean;
  cursor?: number;
  lastupdated?: number;
  microSec?: number;
  timestampentry?: number;
  quantity?: number | null;
  discount?: number | null;
  discountType?: keyof typeof HikeType | null;
  scheme?: IPaymentScheme;
  medicine?: IMedicine | null;
  consumable?: IConsumable | null;
  implant?: IImplant | null;
}

export const defaultValue: Readonly<IPackageMedicine> = {
  active: false,
};
