import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IGlucoUser } from 'app/shared/model/gluco-user.model';
import { getEntities as getGlucoUsers } from 'app/entities/gluco-user/gluco-user.reducer';
import { IRelation } from 'app/shared/model/relation.model';
import { getEntities as getRelations } from 'app/entities/relation/relation.reducer';
import { IPackageSubscription } from 'app/shared/model/package-subscription.model';
import { getEntities as getPackageSubscriptions } from 'app/entities/package-subscription/package-subscription.reducer';
import { IPackageSubscriber } from 'app/shared/model/package-subscriber.model';
import { getEntity, updateEntity, createEntity, reset } from './package-subscriber.reducer';

export const PackageSubscriberUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const glucoUsers = useAppSelector(state => state.glucoUser.entities);
  const relations = useAppSelector(state => state.relation.entities);
  const packageSubscriptions = useAppSelector(state => state.packageSubscription.entities);
  const packageSubscriberEntity = useAppSelector(state => state.packageSubscriber.entity);
  const loading = useAppSelector(state => state.packageSubscriber.loading);
  const updating = useAppSelector(state => state.packageSubscriber.updating);
  const updateSuccess = useAppSelector(state => state.packageSubscriber.updateSuccess);

  const handleClose = () => {
    navigate('/package-subscriber' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getGlucoUsers({}));
    dispatch(getRelations({}));
    dispatch(getPackageSubscriptions({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...packageSubscriberEntity,
      ...values,
      scubscriber: glucoUsers.find(it => it.id.toString() === values.scubscriber?.toString()),
      relationToPrimary: relations.find(it => it.id.toString() === values.relationToPrimary?.toString()),
      subscription: packageSubscriptions.find(it => it.id.toString() === values.subscription?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...packageSubscriberEntity,
          scubscriber: packageSubscriberEntity?.scubscriber?.id,
          relationToPrimary: packageSubscriberEntity?.relationToPrimary?.id,
          subscription: packageSubscriberEntity?.subscription?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="tinyMedicRestApp.packageSubscriber.home.createOrEditLabel" data-cy="PackageSubscriberCreateUpdateHeading">
            <Translate contentKey="tinyMedicRestApp.packageSubscriber.home.createOrEditLabel">Create or edit a PackageSubscriber</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="package-subscriber-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscriber.primaryUser')}
                id="package-subscriber-primaryUser"
                name="primaryUser"
                data-cy="primaryUser"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscriber.active')}
                id="package-subscriber-active"
                name="active"
                data-cy="active"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscriber.cursor')}
                id="package-subscriber-cursor"
                name="cursor"
                data-cy="cursor"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscriber.lastUpdated')}
                id="package-subscriber-lastUpdated"
                name="lastUpdated"
                data-cy="lastUpdated"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscriber.microSec')}
                id="package-subscriber-microSec"
                name="microSec"
                data-cy="microSec"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscriber.timestampEntry')}
                id="package-subscriber-timestampEntry"
                name="timestampEntry"
                data-cy="timestampEntry"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscriber.subscriberNumber')}
                id="package-subscriber-subscriberNumber"
                name="subscriberNumber"
                data-cy="subscriberNumber"
                type="text"
              />
              <ValidatedField
                id="package-subscriber-scubscriber"
                name="scubscriber"
                data-cy="scubscriber"
                label={translate('tinyMedicRestApp.packageSubscriber.scubscriber')}
                type="select"
              >
                <option value="" key="0" />
                {glucoUsers
                  ? glucoUsers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="package-subscriber-relationToPrimary"
                name="relationToPrimary"
                data-cy="relationToPrimary"
                label={translate('tinyMedicRestApp.packageSubscriber.relationToPrimary')}
                type="select"
              >
                <option value="" key="0" />
                {relations
                  ? relations.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="package-subscriber-subscription"
                name="subscription"
                data-cy="subscription"
                label={translate('tinyMedicRestApp.packageSubscriber.subscription')}
                type="select"
              >
                <option value="" key="0" />
                {packageSubscriptions
                  ? packageSubscriptions.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/package-subscriber" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PackageSubscriberUpdate;
