import { IPaymentScheme } from 'app/shared/model/payment-scheme.model';
import { IContactDetails } from 'app/shared/model/contact-details.model';
import { PaymentStatus } from 'app/shared/model/enumerations/payment-status.model';

export interface IPackageSubscription {
  id?: string;
  validFrom?: number;
  validTill?: number;
  remindFrom?: number;
  lockOutOn?: number;
  paidAmount?: number;
  pendingAmount?: number;
  expired?: boolean;
  active?: boolean;
  lastUpdated?: number;
  microSec?: number;
  timestampEntry?: number;
  cursor?: number;
  subscriptionNumber?: string | null;
  suspended?: boolean | null;
  suspendedReason?: string | null;
  suspendedDate?: number | null;
  suspendedBy?: string | null;
  paymentStatus?: keyof typeof PaymentStatus | null;
  scheme?: IPaymentScheme | null;
  billToAddress?: IContactDetails | null;
}

export const defaultValue: Readonly<IPackageSubscription> = {
  expired: false,
  active: false,
  suspended: false,
};
