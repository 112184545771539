import { ILabTestItem } from 'app/shared/model/lab-test-item.model';
import { IDiagLabPanel } from 'app/shared/model/diag-lab-panel.model';

export interface IDiagLabPanelItem {
  id?: string;
  active?: boolean | null;
  lastupdated?: number | null;
  microSec?: number | null;
  timestampentry?: number | null;
  cursor?: number;
  sortOrder?: number | null;
  lcTestItem?: ILabTestItem | null;
  panel?: IDiagLabPanel | null;
  childPanel?: IDiagLabPanel | null;
}

export const defaultValue: Readonly<IDiagLabPanelItem> = {
  active: false,
};
