import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import BloodDonation from './blood-donation';
import BloodDonationDetail from './blood-donation-detail';
import BloodDonationUpdate from './blood-donation-update';
import BloodDonationDeleteDialog from './blood-donation-delete-dialog';

const BloodDonationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<BloodDonation />} />
    <Route path="new" element={<BloodDonationUpdate />} />
    <Route path=":id">
      <Route index element={<BloodDonationDetail />} />
      <Route path="edit" element={<BloodDonationUpdate />} />
      <Route path="delete" element={<BloodDonationDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default BloodDonationRoutes;
