import { IGlucoUser } from 'app/shared/model/gluco-user.model';
import { IRelation } from 'app/shared/model/relation.model';
import { IPackageSubscription } from 'app/shared/model/package-subscription.model';

export interface IPackageSubscriber {
  id?: string;
  primaryUser?: boolean | null;
  active?: boolean;
  cursor?: number;
  lastUpdated?: number;
  microSec?: number;
  timestampEntry?: number;
  subscriberNumber?: string | null;
  scubscriber?: IGlucoUser | null;
  relationToPrimary?: IRelation | null;
  subscription?: IPackageSubscription | null;
}

export const defaultValue: Readonly<IPackageSubscriber> = {
  primaryUser: false,
  active: false,
};
