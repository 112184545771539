import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IGlucoUser } from 'app/shared/model/gluco-user.model';
import { getEntities as getGlucoUsers } from 'app/entities/gluco-user/gluco-user.reducer';
import { IClinicalDictionary } from 'app/shared/model/clinical-dictionary.model';
import { getEntities as getClinicalDictionaries } from 'app/entities/clinical-dictionary/clinical-dictionary.reducer';
import { IBloodDonorFitness } from 'app/shared/model/blood-donor-fitness.model';
import { getEntities as getBloodDonorFitnesses } from 'app/entities/blood-donor-fitness/blood-donor-fitness.reducer';
import { IBloodBank } from 'app/shared/model/blood-bank.model';
import { getEntities as getBloodBanks } from 'app/entities/blood-bank/blood-bank.reducer';
import { IHealthCamp } from 'app/shared/model/health-camp.model';
import { getEntities as getHealthCamps } from 'app/entities/health-camp/health-camp.reducer';
import { IBloodDonation } from 'app/shared/model/blood-donation.model';
import { BloodGroup } from 'app/shared/model/enumerations/blood-group.model';
import { RhType } from 'app/shared/model/enumerations/rh-type.model';
import { getEntity, updateEntity, createEntity, reset } from './blood-donation.reducer';

export const BloodDonationUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const glucoUsers = useAppSelector(state => state.glucoUser.entities);
  const clinicalDictionaries = useAppSelector(state => state.clinicalDictionary.entities);
  const bloodDonorFitnesses = useAppSelector(state => state.bloodDonorFitness.entities);
  const bloodBanks = useAppSelector(state => state.bloodBank.entities);
  const healthCamps = useAppSelector(state => state.healthCamp.entities);
  const bloodDonationEntity = useAppSelector(state => state.bloodDonation.entity);
  const loading = useAppSelector(state => state.bloodDonation.loading);
  const updating = useAppSelector(state => state.bloodDonation.updating);
  const updateSuccess = useAppSelector(state => state.bloodDonation.updateSuccess);
  const bloodGroupValues = Object.keys(BloodGroup);
  const rhTypeValues = Object.keys(RhType);

  const handleClose = () => {
    navigate('/blood-donation' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getGlucoUsers({}));
    dispatch(getClinicalDictionaries({}));
    dispatch(getBloodDonorFitnesses({}));
    dispatch(getBloodBanks({}));
    dispatch(getHealthCamps({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.baggedDate !== undefined && typeof values.baggedDate !== 'number') {
      values.baggedDate = Number(values.baggedDate);
    }
    if (values.consentDate !== undefined && typeof values.consentDate !== 'number') {
      values.consentDate = Number(values.consentDate);
    }
    if (values.collectDate !== undefined && typeof values.collectDate !== 'number') {
      values.collectDate = Number(values.collectDate);
    }
    if (values.startTime !== undefined && typeof values.startTime !== 'number') {
      values.startTime = Number(values.startTime);
    }
    if (values.endTime !== undefined && typeof values.endTime !== 'number') {
      values.endTime = Number(values.endTime);
    }
    if (values.cursor !== undefined && typeof values.cursor !== 'number') {
      values.cursor = Number(values.cursor);
    }
    if (values.lastUpdated !== undefined && typeof values.lastUpdated !== 'number') {
      values.lastUpdated = Number(values.lastUpdated);
    }
    if (values.microSec !== undefined && typeof values.microSec !== 'number') {
      values.microSec = Number(values.microSec);
    }
    if (values.timestampEntry !== undefined && typeof values.timestampEntry !== 'number') {
      values.timestampEntry = Number(values.timestampEntry);
    }
    if (values.extractedAt !== undefined && typeof values.extractedAt !== 'number') {
      values.extractedAt = Number(values.extractedAt);
    }

    const entity = {
      ...bloodDonationEntity,
      ...values,
      donor: glucoUsers.find(it => it.id.toString() === values.donor?.toString()),
      donationType: clinicalDictionaries.find(it => it.id.toString() === values.donationType?.toString()),
      bagType: clinicalDictionaries.find(it => it.id.toString() === values.bagType?.toString()),
      approval: clinicalDictionaries.find(it => it.id.toString() === values.approval?.toString()),
      fitness: bloodDonorFitnesses.find(it => it.id.toString() === values.fitness?.toString()),
      bank: bloodBanks.find(it => it.id.toString() === values.bank?.toString()),
      collectedCamp: healthCamps.find(it => it.id.toString() === values.collectedCamp?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          bloodType: 'O_POS',
          rhType: 'POSITIVE_STRONG',
          ...bloodDonationEntity,
          donor: bloodDonationEntity?.donor?.id,
          donationType: bloodDonationEntity?.donationType?.id,
          bagType: bloodDonationEntity?.bagType?.id,
          approval: bloodDonationEntity?.approval?.id,
          fitness: bloodDonationEntity?.fitness?.id,
          bank: bloodDonationEntity?.bank?.id,
          collectedCamp: bloodDonationEntity?.collectedCamp?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="tinyMedicRestApp.bloodDonation.home.createOrEditLabel" data-cy="BloodDonationCreateUpdateHeading">
            <Translate contentKey="tinyMedicRestApp.bloodDonation.home.createOrEditLabel">Create or edit a BloodDonation</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="blood-donation-id"
                  label={translate('tinyMedicRestApp.bloodDonation.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodDonation.baggedDate')}
                id="blood-donation-baggedDate"
                name="baggedDate"
                data-cy="baggedDate"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodDonation.bagNo')}
                id="blood-donation-bagNo"
                name="bagNo"
                data-cy="bagNo"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodDonation.consentDate')}
                id="blood-donation-consentDate"
                name="consentDate"
                data-cy="consentDate"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodDonation.consentNo')}
                id="blood-donation-consentNo"
                name="consentNo"
                data-cy="consentNo"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodDonation.bloodType')}
                id="blood-donation-bloodType"
                name="bloodType"
                data-cy="bloodType"
                type="select"
              >
                {bloodGroupValues.map(bloodGroup => (
                  <option value={bloodGroup} key={bloodGroup}>
                    {translate('tinyMedicRestApp.BloodGroup.' + bloodGroup)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodDonation.rhType')}
                id="blood-donation-rhType"
                name="rhType"
                data-cy="rhType"
                type="select"
              >
                {rhTypeValues.map(rhType => (
                  <option value={rhType} key={rhType}>
                    {translate('tinyMedicRestApp.RhType.' + rhType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodDonation.tubeID')}
                id="blood-donation-tubeID"
                name="tubeID"
                data-cy="tubeID"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodDonation.collectDate')}
                id="blood-donation-collectDate"
                name="collectDate"
                data-cy="collectDate"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodDonation.startTime')}
                id="blood-donation-startTime"
                name="startTime"
                data-cy="startTime"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodDonation.endTime')}
                id="blood-donation-endTime"
                name="endTime"
                data-cy="endTime"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodDonation.active')}
                id="blood-donation-active"
                name="active"
                data-cy="active"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodDonation.cursor')}
                id="blood-donation-cursor"
                name="cursor"
                data-cy="cursor"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodDonation.lastUpdated')}
                id="blood-donation-lastUpdated"
                name="lastUpdated"
                data-cy="lastUpdated"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodDonation.microSec')}
                id="blood-donation-microSec"
                name="microSec"
                data-cy="microSec"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodDonation.timestampEntry')}
                id="blood-donation-timestampEntry"
                name="timestampEntry"
                data-cy="timestampEntry"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodDonation.extracted')}
                id="blood-donation-extracted"
                name="extracted"
                data-cy="extracted"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodDonation.extractedAt')}
                id="blood-donation-extractedAt"
                name="extractedAt"
                data-cy="extractedAt"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodDonation.notes')}
                id="blood-donation-notes"
                name="notes"
                data-cy="notes"
                type="text"
              />
              <ValidatedField
                id="blood-donation-donor"
                name="donor"
                data-cy="donor"
                label={translate('tinyMedicRestApp.bloodDonation.donor')}
                type="select"
              >
                <option value="" key="0" />
                {glucoUsers
                  ? glucoUsers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="blood-donation-donationType"
                name="donationType"
                data-cy="donationType"
                label={translate('tinyMedicRestApp.bloodDonation.donationType')}
                type="select"
              >
                <option value="" key="0" />
                {clinicalDictionaries
                  ? clinicalDictionaries.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="blood-donation-bagType"
                name="bagType"
                data-cy="bagType"
                label={translate('tinyMedicRestApp.bloodDonation.bagType')}
                type="select"
              >
                <option value="" key="0" />
                {clinicalDictionaries
                  ? clinicalDictionaries.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="blood-donation-approval"
                name="approval"
                data-cy="approval"
                label={translate('tinyMedicRestApp.bloodDonation.approval')}
                type="select"
              >
                <option value="" key="0" />
                {clinicalDictionaries
                  ? clinicalDictionaries.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="blood-donation-fitness"
                name="fitness"
                data-cy="fitness"
                label={translate('tinyMedicRestApp.bloodDonation.fitness')}
                type="select"
              >
                <option value="" key="0" />
                {bloodDonorFitnesses
                  ? bloodDonorFitnesses.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="blood-donation-bank"
                name="bank"
                data-cy="bank"
                label={translate('tinyMedicRestApp.bloodDonation.bank')}
                type="select"
              >
                <option value="" key="0" />
                {bloodBanks
                  ? bloodBanks.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="blood-donation-collectedCamp"
                name="collectedCamp"
                data-cy="collectedCamp"
                label={translate('tinyMedicRestApp.bloodDonation.collectedCamp')}
                type="select"
              >
                <option value="" key="0" />
                {healthCamps
                  ? healthCamps.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/blood-donation" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default BloodDonationUpdate;
