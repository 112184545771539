import { IICD10Procedure } from 'app/shared/model/icd-10-procedure.model';
import { IHospital } from 'app/shared/model/hospital.model';
import { IHospitalManager } from 'app/shared/model/hospital-manager.model';
import { ConsumableType } from 'app/shared/model/enumerations/consumable-type.model';
import { ProcedureType } from 'app/shared/model/enumerations/procedure-type.model';
import { ProcedureCategory } from 'app/shared/model/enumerations/procedure-category.model';

export interface IHospitalProcedure {
  id?: string;
  localName?: keyof typeof ConsumableType | null;
  timestampentry?: number;
  active?: boolean;
  lastupdated?: number;
  proposedPrice?: number | null;
  cursor?: number;
  microSec?: number;
  snomedConceptId?: string;
  snomedConceptText?: string;
  procedureType?: keyof typeof ProcedureType | null;
  procedureCategory?: keyof typeof ProcedureCategory | null;
  admissionRequired?: boolean | null;
  prepareItemOrders?: boolean;
  procedure?: IICD10Procedure | null;
  hospital?: IHospital;
  addedBy?: IHospitalManager;
}

export const defaultValue: Readonly<IHospitalProcedure> = {
  active: false,
  admissionRequired: false,
  prepareItemOrders: false,
};
