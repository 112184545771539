export enum ProcedureCategory {
  SCT_24642003 = 'Psychiatry Procedure Or Service',

  SCT_409063005 = 'Counselling',

  SCT_409073007 = 'Education',

  SCT_387713003 = 'Surgical Procedure',

  SCT_103693007 = 'Diagnostic Procedure',

  SCT_46947000 = 'Chiropractic Manipulation',

  SCT_410606002 = 'Social Service Procedure',
}
