import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IPaymentScheme } from 'app/shared/model/payment-scheme.model';
import { getEntities as getPaymentSchemes } from 'app/entities/payment-scheme/payment-scheme.reducer';
import { IContactDetails } from 'app/shared/model/contact-details.model';
import { getEntities as getContactDetails } from 'app/entities/contact-details/contact-details.reducer';
import { IPackageSubscription } from 'app/shared/model/package-subscription.model';
import { PaymentStatus } from 'app/shared/model/enumerations/payment-status.model';
import { getEntity, updateEntity, createEntity, reset } from './package-subscription.reducer';

export const PackageSubscriptionUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const paymentSchemes = useAppSelector(state => state.paymentScheme.entities);
  const contactDetails = useAppSelector(state => state.contactDetails.entities);
  const packageSubscriptionEntity = useAppSelector(state => state.packageSubscription.entity);
  const loading = useAppSelector(state => state.packageSubscription.loading);
  const updating = useAppSelector(state => state.packageSubscription.updating);
  const updateSuccess = useAppSelector(state => state.packageSubscription.updateSuccess);
  const paymentStatusValues = Object.keys(PaymentStatus);

  const handleClose = () => {
    navigate('/package-subscription' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getPaymentSchemes({}));
    dispatch(getContactDetails({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...packageSubscriptionEntity,
      ...values,
      scheme: paymentSchemes.find(it => it.id.toString() === values.scheme?.toString()),
      billToAddress: contactDetails.find(it => it.id.toString() === values.billToAddress?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          paymentStatus: 'ENTERED',
          ...packageSubscriptionEntity,
          scheme: packageSubscriptionEntity?.scheme?.id,
          billToAddress: packageSubscriptionEntity?.billToAddress?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="tinyMedicRestApp.packageSubscription.home.createOrEditLabel" data-cy="PackageSubscriptionCreateUpdateHeading">
            <Translate contentKey="tinyMedicRestApp.packageSubscription.home.createOrEditLabel">
              Create or edit a PackageSubscription
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="package-subscription-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscription.validFrom')}
                id="package-subscription-validFrom"
                name="validFrom"
                data-cy="validFrom"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscription.validTill')}
                id="package-subscription-validTill"
                name="validTill"
                data-cy="validTill"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscription.remindFrom')}
                id="package-subscription-remindFrom"
                name="remindFrom"
                data-cy="remindFrom"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscription.lockOutOn')}
                id="package-subscription-lockOutOn"
                name="lockOutOn"
                data-cy="lockOutOn"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscription.paidAmount')}
                id="package-subscription-paidAmount"
                name="paidAmount"
                data-cy="paidAmount"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscription.pendingAmount')}
                id="package-subscription-pendingAmount"
                name="pendingAmount"
                data-cy="pendingAmount"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscription.expired')}
                id="package-subscription-expired"
                name="expired"
                data-cy="expired"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscription.active')}
                id="package-subscription-active"
                name="active"
                data-cy="active"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscription.lastUpdated')}
                id="package-subscription-lastUpdated"
                name="lastUpdated"
                data-cy="lastUpdated"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscription.microSec')}
                id="package-subscription-microSec"
                name="microSec"
                data-cy="microSec"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscription.timestampEntry')}
                id="package-subscription-timestampEntry"
                name="timestampEntry"
                data-cy="timestampEntry"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscription.cursor')}
                id="package-subscription-cursor"
                name="cursor"
                data-cy="cursor"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscription.subscriptionNumber')}
                id="package-subscription-subscriptionNumber"
                name="subscriptionNumber"
                data-cy="subscriptionNumber"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscription.suspended')}
                id="package-subscription-suspended"
                name="suspended"
                data-cy="suspended"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscription.suspendedReason')}
                id="package-subscription-suspendedReason"
                name="suspendedReason"
                data-cy="suspendedReason"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscription.suspendedDate')}
                id="package-subscription-suspendedDate"
                name="suspendedDate"
                data-cy="suspendedDate"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscription.suspendedBy')}
                id="package-subscription-suspendedBy"
                name="suspendedBy"
                data-cy="suspendedBy"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageSubscription.paymentStatus')}
                id="package-subscription-paymentStatus"
                name="paymentStatus"
                data-cy="paymentStatus"
                type="select"
              >
                {paymentStatusValues.map(paymentStatus => (
                  <option value={paymentStatus} key={paymentStatus}>
                    {translate('tinyMedicRestApp.PaymentStatus.' + paymentStatus)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                id="package-subscription-scheme"
                name="scheme"
                data-cy="scheme"
                label={translate('tinyMedicRestApp.packageSubscription.scheme')}
                type="select"
              >
                <option value="" key="0" />
                {paymentSchemes
                  ? paymentSchemes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="package-subscription-billToAddress"
                name="billToAddress"
                data-cy="billToAddress"
                label={translate('tinyMedicRestApp.packageSubscription.billToAddress')}
                type="select"
              >
                <option value="" key="0" />
                {contactDetails
                  ? contactDetails.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/package-subscription" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PackageSubscriptionUpdate;
