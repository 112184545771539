import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IPaymentScheme } from 'app/shared/model/payment-scheme.model';
import { getEntities as getPaymentSchemes } from 'app/entities/payment-scheme/payment-scheme.reducer';
import { IMedicine } from 'app/shared/model/medicine.model';
import { getEntities as getMedicines } from 'app/entities/medicine/medicine.reducer';
import { IConsumable } from 'app/shared/model/consumable.model';
import { getEntities as getConsumables } from 'app/entities/consumable/consumable.reducer';
import { IImplant } from 'app/shared/model/implant.model';
import { getEntities as getImplants } from 'app/entities/implant/implant.reducer';
import { IPackageMedicine } from 'app/shared/model/package-medicine.model';
import { HikeType } from 'app/shared/model/enumerations/hike-type.model';
import { getEntity, updateEntity, createEntity, reset } from './package-medicine.reducer';

export const PackageMedicineUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const paymentSchemes = useAppSelector(state => state.paymentScheme.entities);
  const medicines = useAppSelector(state => state.medicine.entities);
  const consumables = useAppSelector(state => state.consumable.entities);
  const implants = useAppSelector(state => state.implant.entities);
  const packageMedicineEntity = useAppSelector(state => state.packageMedicine.entity);
  const loading = useAppSelector(state => state.packageMedicine.loading);
  const updating = useAppSelector(state => state.packageMedicine.updating);
  const updateSuccess = useAppSelector(state => state.packageMedicine.updateSuccess);
  const hikeTypeValues = Object.keys(HikeType);

  const handleClose = () => {
    navigate('/package-medicine' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getPaymentSchemes({}));
    dispatch(getMedicines({}));
    dispatch(getConsumables({}));
    dispatch(getImplants({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...packageMedicineEntity,
      ...values,
      scheme: paymentSchemes.find(it => it.id.toString() === values.scheme?.toString()),
      medicine: medicines.find(it => it.id.toString() === values.medicine?.toString()),
      consumable: consumables.find(it => it.id.toString() === values.consumable?.toString()),
      implant: implants.find(it => it.id.toString() === values.implant?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          discountType: 'PERCENTAGE',
          ...packageMedicineEntity,
          scheme: packageMedicineEntity?.scheme?.id,
          medicine: packageMedicineEntity?.medicine?.id,
          consumable: packageMedicineEntity?.consumable?.id,
          implant: packageMedicineEntity?.implant?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="tinyMedicRestApp.packageMedicine.home.createOrEditLabel" data-cy="PackageMedicineCreateUpdateHeading">
            <Translate contentKey="tinyMedicRestApp.packageMedicine.home.createOrEditLabel">Create or edit a PackageMedicine</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="package-medicine-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('tinyMedicRestApp.packageMedicine.active')}
                id="package-medicine-active"
                name="active"
                data-cy="active"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageMedicine.cursor')}
                id="package-medicine-cursor"
                name="cursor"
                data-cy="cursor"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageMedicine.lastupdated')}
                id="package-medicine-lastupdated"
                name="lastupdated"
                data-cy="lastupdated"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageMedicine.microSec')}
                id="package-medicine-microSec"
                name="microSec"
                data-cy="microSec"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageMedicine.timestampentry')}
                id="package-medicine-timestampentry"
                name="timestampentry"
                data-cy="timestampentry"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageMedicine.quantity')}
                id="package-medicine-quantity"
                name="quantity"
                data-cy="quantity"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageMedicine.discount')}
                id="package-medicine-discount"
                name="discount"
                data-cy="discount"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.packageMedicine.discountType')}
                id="package-medicine-discountType"
                name="discountType"
                data-cy="discountType"
                type="select"
              >
                {hikeTypeValues.map(hikeType => (
                  <option value={hikeType} key={hikeType}>
                    {translate('tinyMedicRestApp.HikeType.' + hikeType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                id="package-medicine-scheme"
                name="scheme"
                data-cy="scheme"
                label={translate('tinyMedicRestApp.packageMedicine.scheme')}
                type="select"
                required
              >
                <option value="" key="0" />
                {paymentSchemes
                  ? paymentSchemes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <ValidatedField
                id="package-medicine-medicine"
                name="medicine"
                data-cy="medicine"
                label={translate('tinyMedicRestApp.packageMedicine.medicine')}
                type="select"
              >
                <option value="" key="0" />
                {medicines
                  ? medicines.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="package-medicine-consumable"
                name="consumable"
                data-cy="consumable"
                label={translate('tinyMedicRestApp.packageMedicine.consumable')}
                type="select"
              >
                <option value="" key="0" />
                {consumables
                  ? consumables.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="package-medicine-implant"
                name="implant"
                data-cy="implant"
                label={translate('tinyMedicRestApp.packageMedicine.implant')}
                type="select"
              >
                <option value="" key="0" />
                {implants
                  ? implants.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/package-medicine" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PackageMedicineUpdate;
