export enum ProcedureType {
  MAJOR_PROCEDURE = 'Major Procedure',

  MINOR_PROCEDURE = 'Minor Procedure',

  DAYCARE_PROCEDURE = 'Daycare Procedure',

  MAJOR_SURGERY = 'Major Surgery',

  MINOR_SURGERY = 'Minor Surgery',

  DAYCARE_SURGERY = 'Daycare Surgery',
}
