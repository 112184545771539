import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './admission-em-contacts.reducer';

export const AdmissionEMContactsDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const admissionEMContactsEntity = useAppSelector(state => state.admissionEMContacts.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="admissionEMContactsDetailsHeading">
          <Translate contentKey="tinyMedicRestApp.admissionEMContacts.detail.title">AdmissionEMContacts</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="tinyMedicRestApp.admissionEMContacts.id">Id</Translate>
            </span>
          </dt>
          <dd>{admissionEMContactsEntity.id}</dd>
          <dt>
            <span id="phone">
              <Translate contentKey="tinyMedicRestApp.admissionEMContacts.phone">Phone</Translate>
            </span>
          </dt>
          <dd>{admissionEMContactsEntity.phone}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="tinyMedicRestApp.admissionEMContacts.name">Name</Translate>
            </span>
          </dt>
          <dd>{admissionEMContactsEntity.name}</dd>
          <dt>
            <span id="orderOfPriority">
              <Translate contentKey="tinyMedicRestApp.admissionEMContacts.orderOfPriority">Order Of Priority</Translate>
            </span>
          </dt>
          <dd>{admissionEMContactsEntity.orderOfPriority}</dd>
          <dt>
            <span id="timestampentry">
              <Translate contentKey="tinyMedicRestApp.admissionEMContacts.timestampentry">Timestampentry</Translate>
            </span>
          </dt>
          <dd>{admissionEMContactsEntity.timestampentry}</dd>
          <dt>
            <span id="microSec">
              <Translate contentKey="tinyMedicRestApp.admissionEMContacts.microSec">Micro Sec</Translate>
            </span>
          </dt>
          <dd>{admissionEMContactsEntity.microSec}</dd>
          <dt>
            <span id="lastupdated">
              <Translate contentKey="tinyMedicRestApp.admissionEMContacts.lastupdated">Lastupdated</Translate>
            </span>
          </dt>
          <dd>{admissionEMContactsEntity.lastupdated}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="tinyMedicRestApp.admissionEMContacts.active">Active</Translate>
            </span>
          </dt>
          <dd>{admissionEMContactsEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="cursor">
              <Translate contentKey="tinyMedicRestApp.admissionEMContacts.cursor">Cursor</Translate>
            </span>
          </dt>
          <dd>{admissionEMContactsEntity.cursor}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.admissionEMContacts.glucoUser">Gluco User</Translate>
          </dt>
          <dd>{admissionEMContactsEntity.glucoUser ? admissionEMContactsEntity.glucoUser.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.admissionEMContacts.admission">Admission</Translate>
          </dt>
          <dd>{admissionEMContactsEntity.admission ? admissionEMContactsEntity.admission.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.admissionEMContacts.contactDetails">Contact Details</Translate>
          </dt>
          <dd>{admissionEMContactsEntity.contactDetails ? admissionEMContactsEntity.contactDetails.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/admission-em-contacts" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/admission-em-contacts/${admissionEMContactsEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default AdmissionEMContactsDetail;
