import { IPackageSubscriber } from 'app/shared/model/package-subscriber.model';
import { BillType } from 'app/shared/model/enumerations/bill-type.model';
import { BillOrigin } from 'app/shared/model/enumerations/bill-origin.model';

export interface IPackageSubscriptionDiscount {
  id?: string;
  discount?: number | null;
  actualDiscount?: number | null;
  billNumber?: string | null;
  billDate?: number;
  billType?: keyof typeof BillType;
  billId?: string;
  active?: boolean;
  cursor?: number;
  lastUpdated?: number;
  microSec?: number;
  timestampEntry?: number;
  remarks?: string | null;
  exception?: boolean | null;
  billOrigin?: keyof typeof BillOrigin | null;
  usedBy?: IPackageSubscriber | null;
}

export const defaultValue: Readonly<IPackageSubscriptionDiscount> = {
  active: false,
  exception: false,
};
