import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import BloodBankInventory from './blood-bank-inventory';
import BloodBankInventoryDetail from './blood-bank-inventory-detail';
import BloodBankInventoryUpdate from './blood-bank-inventory-update';
import BloodBankInventoryDeleteDialog from './blood-bank-inventory-delete-dialog';

const BloodBankInventoryRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<BloodBankInventory />} />
    <Route path="new" element={<BloodBankInventoryUpdate />} />
    <Route path=":id">
      <Route index element={<BloodBankInventoryDetail />} />
      <Route path="edit" element={<BloodBankInventoryUpdate />} />
      <Route path="delete" element={<BloodBankInventoryDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default BloodBankInventoryRoutes;
