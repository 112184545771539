import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IBloodBankEmployee } from 'app/shared/model/blood-bank-employee.model';
import { getEntities as getBloodBankEmployees } from 'app/entities/blood-bank-employee/blood-bank-employee.reducer';
import { IBloodBank } from 'app/shared/model/blood-bank.model';
import { getEntities as getBloodBanks } from 'app/entities/blood-bank/blood-bank.reducer';
import { IBloodDonation } from 'app/shared/model/blood-donation.model';
import { getEntities as getBloodDonations } from 'app/entities/blood-donation/blood-donation.reducer';
import { IClinicalDictionary } from 'app/shared/model/clinical-dictionary.model';
import { getEntities as getClinicalDictionaries } from 'app/entities/clinical-dictionary/clinical-dictionary.reducer';
import { IBloodBankInventory } from 'app/shared/model/blood-bank-inventory.model';
import { BloodGroup } from 'app/shared/model/enumerations/blood-group.model';
import { RhType } from 'app/shared/model/enumerations/rh-type.model';
import { BloodProducts } from 'app/shared/model/enumerations/blood-products.model';
import { getEntity, updateEntity, createEntity, reset } from './blood-bank-inventory.reducer';

export const BloodBankInventoryUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const bloodBankEmployees = useAppSelector(state => state.bloodBankEmployee.entities);
  const bloodBanks = useAppSelector(state => state.bloodBank.entities);
  const bloodDonations = useAppSelector(state => state.bloodDonation.entities);
  const clinicalDictionaries = useAppSelector(state => state.clinicalDictionary.entities);
  const bloodBankInventoryEntity = useAppSelector(state => state.bloodBankInventory.entity);
  const loading = useAppSelector(state => state.bloodBankInventory.loading);
  const updating = useAppSelector(state => state.bloodBankInventory.updating);
  const updateSuccess = useAppSelector(state => state.bloodBankInventory.updateSuccess);
  const bloodGroupValues = Object.keys(BloodGroup);
  const rhTypeValues = Object.keys(RhType);
  const bloodProductsValues = Object.keys(BloodProducts);

  const handleClose = () => {
    navigate('/blood-bank-inventory' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getBloodBankEmployees({}));
    dispatch(getBloodBanks({}));
    dispatch(getBloodDonations({}));
    dispatch(getClinicalDictionaries({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.bloodBagNumber !== undefined && typeof values.bloodBagNumber !== 'number') {
      values.bloodBagNumber = Number(values.bloodBagNumber);
    }
    if (values.collectionDate !== undefined && typeof values.collectionDate !== 'number') {
      values.collectionDate = Number(values.collectionDate);
    }
    if (values.expiryDate !== undefined && typeof values.expiryDate !== 'number') {
      values.expiryDate = Number(values.expiryDate);
    }
    if (values.cursor !== undefined && typeof values.cursor !== 'number') {
      values.cursor = Number(values.cursor);
    }
    if (values.lastUpdated !== undefined && typeof values.lastUpdated !== 'number') {
      values.lastUpdated = Number(values.lastUpdated);
    }
    if (values.microSec !== undefined && typeof values.microSec !== 'number') {
      values.microSec = Number(values.microSec);
    }
    if (values.timestampEntry !== undefined && typeof values.timestampEntry !== 'number') {
      values.timestampEntry = Number(values.timestampEntry);
    }
    if (values.volume !== undefined && typeof values.volume !== 'number') {
      values.volume = Number(values.volume);
    }

    const entity = {
      ...bloodBankInventoryEntity,
      ...values,
      validator: bloodBankEmployees.find(it => it.id.toString() === values.validator?.toString()),
      bank: bloodBanks.find(it => it.id.toString() === values.bank?.toString()),
      donation: bloodDonations.find(it => it.id.toString() === values.donation?.toString()),
      status: clinicalDictionaries.find(it => it.id.toString() === values.status?.toString()),
      productComponent: clinicalDictionaries.find(it => it.id.toString() === values.productComponent?.toString()),
      donationApproval: clinicalDictionaries.find(it => it.id.toString() === values.donationApproval?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          bloodType: 'O_POS',
          rhType: 'POSITIVE_STRONG',
          productType: 'WB',
          ...bloodBankInventoryEntity,
          validator: bloodBankInventoryEntity?.validator?.id,
          bank: bloodBankInventoryEntity?.bank?.id,
          donation: bloodBankInventoryEntity?.donation?.id,
          status: bloodBankInventoryEntity?.status?.id,
          productComponent: bloodBankInventoryEntity?.productComponent?.id,
          donationApproval: bloodBankInventoryEntity?.donationApproval?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="tinyMedicRestApp.bloodBankInventory.home.createOrEditLabel" data-cy="BloodBankInventoryCreateUpdateHeading">
            <Translate contentKey="tinyMedicRestApp.bloodBankInventory.home.createOrEditLabel">
              Create or edit a BloodBankInventory
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="blood-bank-inventory-id"
                  label={translate('tinyMedicRestApp.bloodBankInventory.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodBankInventory.bloodBagNumber')}
                id="blood-bank-inventory-bloodBagNumber"
                name="bloodBagNumber"
                data-cy="bloodBagNumber"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodBankInventory.bloodType')}
                id="blood-bank-inventory-bloodType"
                name="bloodType"
                data-cy="bloodType"
                type="select"
              >
                {bloodGroupValues.map(bloodGroup => (
                  <option value={bloodGroup} key={bloodGroup}>
                    {translate('tinyMedicRestApp.BloodGroup.' + bloodGroup)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodBankInventory.rhType')}
                id="blood-bank-inventory-rhType"
                name="rhType"
                data-cy="rhType"
                type="select"
              >
                {rhTypeValues.map(rhType => (
                  <option value={rhType} key={rhType}>
                    {translate('tinyMedicRestApp.RhType.' + rhType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodBankInventory.productType')}
                id="blood-bank-inventory-productType"
                name="productType"
                data-cy="productType"
                type="select"
              >
                {bloodProductsValues.map(bloodProducts => (
                  <option value={bloodProducts} key={bloodProducts}>
                    {translate('tinyMedicRestApp.BloodProducts.' + bloodProducts)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodBankInventory.collectionDate')}
                id="blood-bank-inventory-collectionDate"
                name="collectionDate"
                data-cy="collectionDate"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodBankInventory.expiryDate')}
                id="blood-bank-inventory-expiryDate"
                name="expiryDate"
                data-cy="expiryDate"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodBankInventory.storageLocation')}
                id="blood-bank-inventory-storageLocation"
                name="storageLocation"
                data-cy="storageLocation"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodBankInventory.active')}
                id="blood-bank-inventory-active"
                name="active"
                data-cy="active"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodBankInventory.cursor')}
                id="blood-bank-inventory-cursor"
                name="cursor"
                data-cy="cursor"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodBankInventory.lastUpdated')}
                id="blood-bank-inventory-lastUpdated"
                name="lastUpdated"
                data-cy="lastUpdated"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodBankInventory.microSec')}
                id="blood-bank-inventory-microSec"
                name="microSec"
                data-cy="microSec"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodBankInventory.timestampEntry')}
                id="blood-bank-inventory-timestampEntry"
                name="timestampEntry"
                data-cy="timestampEntry"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodBankInventory.volume')}
                id="blood-bank-inventory-volume"
                name="volume"
                data-cy="volume"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.bloodBankInventory.notes')}
                id="blood-bank-inventory-notes"
                name="notes"
                data-cy="notes"
                type="text"
              />
              <ValidatedField
                id="blood-bank-inventory-validator"
                name="validator"
                data-cy="validator"
                label={translate('tinyMedicRestApp.bloodBankInventory.validator')}
                type="select"
              >
                <option value="" key="0" />
                {bloodBankEmployees
                  ? bloodBankEmployees.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="blood-bank-inventory-bank"
                name="bank"
                data-cy="bank"
                label={translate('tinyMedicRestApp.bloodBankInventory.bank')}
                type="select"
              >
                <option value="" key="0" />
                {bloodBanks
                  ? bloodBanks.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="blood-bank-inventory-donation"
                name="donation"
                data-cy="donation"
                label={translate('tinyMedicRestApp.bloodBankInventory.donation')}
                type="select"
              >
                <option value="" key="0" />
                {bloodDonations
                  ? bloodDonations.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="blood-bank-inventory-status"
                name="status"
                data-cy="status"
                label={translate('tinyMedicRestApp.bloodBankInventory.status')}
                type="select"
              >
                <option value="" key="0" />
                {clinicalDictionaries
                  ? clinicalDictionaries.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="blood-bank-inventory-productComponent"
                name="productComponent"
                data-cy="productComponent"
                label={translate('tinyMedicRestApp.bloodBankInventory.productComponent')}
                type="select"
              >
                <option value="" key="0" />
                {clinicalDictionaries
                  ? clinicalDictionaries.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="blood-bank-inventory-donationApproval"
                name="donationApproval"
                data-cy="donationApproval"
                label={translate('tinyMedicRestApp.bloodBankInventory.donationApproval')}
                type="select"
              >
                <option value="" key="0" />
                {clinicalDictionaries
                  ? clinicalDictionaries.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/blood-bank-inventory" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default BloodBankInventoryUpdate;
