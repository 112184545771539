import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import HospitalProcedure from './hospital-procedure';
import HospitalProcedureDetail from './hospital-procedure-detail';
import HospitalProcedureUpdate from './hospital-procedure-update';
import HospitalProcedureDeleteDialog from './hospital-procedure-delete-dialog';

const HospitalProcedureRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<HospitalProcedure />} />
    <Route path="new" element={<HospitalProcedureUpdate />} />
    <Route path=":id">
      <Route index element={<HospitalProcedureDetail />} />
      <Route path="edit" element={<HospitalProcedureUpdate />} />
      <Route path="delete" element={<HospitalProcedureDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default HospitalProcedureRoutes;
