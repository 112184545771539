import { IGlucoUser } from 'app/shared/model/gluco-user.model';
import { IClinicalDictionary } from 'app/shared/model/clinical-dictionary.model';
import { IBloodDonorFitness } from 'app/shared/model/blood-donor-fitness.model';
import { IBloodBank } from 'app/shared/model/blood-bank.model';
import { IHealthCamp } from 'app/shared/model/health-camp.model';
import { BloodGroup } from 'app/shared/model/enumerations/blood-group.model';
import { RhType } from 'app/shared/model/enumerations/rh-type.model';

export interface IBloodDonation {
  id?: string;
  baggedDate?: number | null;
  bagNo?: string | null;
  consentDate?: number | null;
  consentNo?: string | null;
  bloodType?: keyof typeof BloodGroup | null;
  rhType?: keyof typeof RhType | null;
  tubeID?: string | null;
  collectDate?: number | null;
  startTime?: number | null;
  endTime?: number | null;
  active?: boolean | null;
  cursor?: number | null;
  lastUpdated?: number | null;
  microSec?: number | null;
  timestampEntry?: number | null;
  extracted?: boolean | null;
  extractedAt?: number | null;
  notes?: string | null;
  donor?: IGlucoUser | null;
  donationType?: IClinicalDictionary | null;
  bagType?: IClinicalDictionary | null;
  approval?: IClinicalDictionary | null;
  fitness?: IBloodDonorFitness | null;
  bank?: IBloodBank | null;
  collectedCamp?: IHealthCamp | null;
}

export const defaultValue: Readonly<IBloodDonation> = {
  active: false,
  extracted: false,
};
