import { IGlucoUser } from 'app/shared/model/gluco-user.model';
import { IAdmission } from 'app/shared/model/admission.model';
import { IContactDetails } from 'app/shared/model/contact-details.model';

export interface IAdmissionEMContacts {
  id?: string;
  phone?: string;
  name?: string;
  orderOfPriority?: number;
  timestampentry?: number;
  microSec?: number;
  lastupdated?: number;
  active?: boolean;
  cursor?: number;
  glucoUser?: IGlucoUser | null;
  admission?: IAdmission;
  contactDetails?: IContactDetails | null;
}

export const defaultValue: Readonly<IAdmissionEMContacts> = {
  active: false,
};
