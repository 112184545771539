import { IBloodBankEmployee } from 'app/shared/model/blood-bank-employee.model';
import { IBloodBank } from 'app/shared/model/blood-bank.model';
import { IBloodDonation } from 'app/shared/model/blood-donation.model';
import { IClinicalDictionary } from 'app/shared/model/clinical-dictionary.model';
import { BloodGroup } from 'app/shared/model/enumerations/blood-group.model';
import { RhType } from 'app/shared/model/enumerations/rh-type.model';
import { BloodProducts } from 'app/shared/model/enumerations/blood-products.model';

export interface IBloodBankInventory {
  id?: string;
  bloodBagNumber?: number | null;
  bloodType?: keyof typeof BloodGroup | null;
  rhType?: keyof typeof RhType | null;
  productType?: keyof typeof BloodProducts | null;
  collectionDate?: number | null;
  expiryDate?: number | null;
  storageLocation?: string | null;
  active?: boolean | null;
  cursor?: number | null;
  lastUpdated?: number | null;
  microSec?: number | null;
  timestampEntry?: number | null;
  volume?: number | null;
  notes?: string | null;
  validator?: IBloodBankEmployee | null;
  bank?: IBloodBank | null;
  donation?: IBloodDonation | null;
  status?: IClinicalDictionary | null;
  productComponent?: IClinicalDictionary | null;
  donationApproval?: IClinicalDictionary | null;
}

export const defaultValue: Readonly<IBloodBankInventory> = {
  active: false,
};
