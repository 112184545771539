import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IICD10Procedure } from 'app/shared/model/icd-10-procedure.model';
import { getEntities as getICD10Procedures } from 'app/entities/icd-10-procedure/icd-10-procedure.reducer';
import { IHospital } from 'app/shared/model/hospital.model';
import { getEntities as getHospitals } from 'app/entities/hospital/hospital.reducer';
import { IHospitalManager } from 'app/shared/model/hospital-manager.model';
import { getEntities as getHospitalManagers } from 'app/entities/hospital-manager/hospital-manager.reducer';
import { IHospitalProcedure } from 'app/shared/model/hospital-procedure.model';
import { ConsumableType } from 'app/shared/model/enumerations/consumable-type.model';
import { ProcedureType } from 'app/shared/model/enumerations/procedure-type.model';
import { ProcedureCategory } from 'app/shared/model/enumerations/procedure-category.model';
import { getEntity, updateEntity, createEntity, reset } from './hospital-procedure.reducer';

export const HospitalProcedureUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const iCD10Procedures = useAppSelector(state => state.iCD10Procedure.entities);
  const hospitals = useAppSelector(state => state.hospital.entities);
  const hospitalManagers = useAppSelector(state => state.hospitalManager.entities);
  const hospitalProcedureEntity = useAppSelector(state => state.hospitalProcedure.entity);
  const loading = useAppSelector(state => state.hospitalProcedure.loading);
  const updating = useAppSelector(state => state.hospitalProcedure.updating);
  const updateSuccess = useAppSelector(state => state.hospitalProcedure.updateSuccess);
  const consumableTypeValues = Object.keys(ConsumableType);
  const procedureTypeValues = Object.keys(ProcedureType);
  const procedureCategoryValues = Object.keys(ProcedureCategory);

  const handleClose = () => {
    navigate('/hospital-procedure' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getICD10Procedures({}));
    dispatch(getHospitals({}));
    dispatch(getHospitalManagers({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.timestampentry !== undefined && typeof values.timestampentry !== 'number') {
      values.timestampentry = Number(values.timestampentry);
    }
    if (values.lastupdated !== undefined && typeof values.lastupdated !== 'number') {
      values.lastupdated = Number(values.lastupdated);
    }
    if (values.proposedPrice !== undefined && typeof values.proposedPrice !== 'number') {
      values.proposedPrice = Number(values.proposedPrice);
    }
    if (values.cursor !== undefined && typeof values.cursor !== 'number') {
      values.cursor = Number(values.cursor);
    }
    if (values.microSec !== undefined && typeof values.microSec !== 'number') {
      values.microSec = Number(values.microSec);
    }

    const entity = {
      ...hospitalProcedureEntity,
      ...values,
      procedure: iCD10Procedures.find(it => it.id.toString() === values.procedure?.toString()),
      hospital: hospitals.find(it => it.id.toString() === values.hospital?.toString()),
      addedBy: hospitalManagers.find(it => it.id.toString() === values.addedBy?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          localName: 'PHARMACEUTICAL',
          procedureType: 'MAJOR_PROCEDURE',
          procedureCategory: 'SCT_24642003',
          ...hospitalProcedureEntity,
          procedure: hospitalProcedureEntity?.procedure?.id,
          hospital: hospitalProcedureEntity?.hospital?.id,
          addedBy: hospitalProcedureEntity?.addedBy?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="tinyMedicRestApp.hospitalProcedure.home.createOrEditLabel" data-cy="HospitalProcedureCreateUpdateHeading">
            <Translate contentKey="tinyMedicRestApp.hospitalProcedure.home.createOrEditLabel">Create or edit a HospitalProcedure</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="hospital-procedure-id"
                  label={translate('tinyMedicRestApp.hospitalProcedure.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalProcedure.localName')}
                id="hospital-procedure-localName"
                name="localName"
                data-cy="localName"
                type="select"
              >
                {consumableTypeValues.map(consumableType => (
                  <option value={consumableType} key={consumableType}>
                    {translate('tinyMedicRestApp.ConsumableType.' + consumableType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalProcedure.timestampentry')}
                id="hospital-procedure-timestampentry"
                name="timestampentry"
                data-cy="timestampentry"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalProcedure.active')}
                id="hospital-procedure-active"
                name="active"
                data-cy="active"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalProcedure.lastupdated')}
                id="hospital-procedure-lastupdated"
                name="lastupdated"
                data-cy="lastupdated"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalProcedure.proposedPrice')}
                id="hospital-procedure-proposedPrice"
                name="proposedPrice"
                data-cy="proposedPrice"
                type="text"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalProcedure.cursor')}
                id="hospital-procedure-cursor"
                name="cursor"
                data-cy="cursor"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalProcedure.microSec')}
                id="hospital-procedure-microSec"
                name="microSec"
                data-cy="microSec"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalProcedure.snomedConceptId')}
                id="hospital-procedure-snomedConceptId"
                name="snomedConceptId"
                data-cy="snomedConceptId"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalProcedure.snomedConceptText')}
                id="hospital-procedure-snomedConceptText"
                name="snomedConceptText"
                data-cy="snomedConceptText"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalProcedure.procedureType')}
                id="hospital-procedure-procedureType"
                name="procedureType"
                data-cy="procedureType"
                type="select"
              >
                {procedureTypeValues.map(procedureType => (
                  <option value={procedureType} key={procedureType}>
                    {translate('tinyMedicRestApp.ProcedureType.' + procedureType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalProcedure.procedureCategory')}
                id="hospital-procedure-procedureCategory"
                name="procedureCategory"
                data-cy="procedureCategory"
                type="select"
              >
                {procedureCategoryValues.map(procedureCategory => (
                  <option value={procedureCategory} key={procedureCategory}>
                    {translate('tinyMedicRestApp.ProcedureCategory.' + procedureCategory)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalProcedure.admissionRequired')}
                id="hospital-procedure-admissionRequired"
                name="admissionRequired"
                data-cy="admissionRequired"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('tinyMedicRestApp.hospitalProcedure.prepareItemOrders')}
                id="hospital-procedure-prepareItemOrders"
                name="prepareItemOrders"
                data-cy="prepareItemOrders"
                check
                type="checkbox"
              />
              <ValidatedField
                id="hospital-procedure-procedure"
                name="procedure"
                data-cy="procedure"
                label={translate('tinyMedicRestApp.hospitalProcedure.procedure')}
                type="select"
              >
                <option value="" key="0" />
                {iCD10Procedures
                  ? iCD10Procedures.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="hospital-procedure-hospital"
                name="hospital"
                data-cy="hospital"
                label={translate('tinyMedicRestApp.hospitalProcedure.hospital')}
                type="select"
                required
              >
                <option value="" key="0" />
                {hospitals
                  ? hospitals.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <ValidatedField
                id="hospital-procedure-addedBy"
                name="addedBy"
                data-cy="addedBy"
                label={translate('tinyMedicRestApp.hospitalProcedure.addedBy')}
                type="select"
                required
              >
                <option value="" key="0" />
                {hospitalManagers
                  ? hospitalManagers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/hospital-procedure" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default HospitalProcedureUpdate;
