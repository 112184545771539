import { IHospitalProcedure } from 'app/shared/model/hospital-procedure.model';

export interface IHospitalProcedureItem {
  id?: string;
  active?: boolean;
  cursor?: number;
  lastUpdated?: number;
  microSec?: number;
  timestampEntry?: number;
  holder?: IHospitalProcedure;
  item?: IHospitalProcedure;
}

export const defaultValue: Readonly<IHospitalProcedureItem> = {
  active: false,
};
