export enum BloodProducts {
  WB = 'Whole Blood',

  PRBC = 'Packed Red Blood Cells',

  FFP = 'FFP - Fresh Frozen Plasma',

  CRYP = 'Cryoprecipitate',

  TCC = 'Thrombin Concentrate',

  RDP = 'RDP - Random Donor Platelets',

  SDP = 'Single Donor Platelets',

  FVIII = 'Factor VIII Concentrates',

  FIX = 'Factor IX Concentrates',

  FB = 'Fresh Blood',

  LBC = 'Leukocyte Reduced Red Blood Cells',

  WRC = 'Washed Red Cells',

  FRC = 'Frozen Red Cells',
}
