import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './hospital-procedure.reducer';

export const HospitalProcedureDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const hospitalProcedureEntity = useAppSelector(state => state.hospitalProcedure.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="hospitalProcedureDetailsHeading">
          <Translate contentKey="tinyMedicRestApp.hospitalProcedure.detail.title">HospitalProcedure</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="tinyMedicRestApp.hospitalProcedure.id">Id</Translate>
            </span>
          </dt>
          <dd>{hospitalProcedureEntity.id}</dd>
          <dt>
            <span id="localName">
              <Translate contentKey="tinyMedicRestApp.hospitalProcedure.localName">Local Name</Translate>
            </span>
          </dt>
          <dd>{hospitalProcedureEntity.localName}</dd>
          <dt>
            <span id="timestampentry">
              <Translate contentKey="tinyMedicRestApp.hospitalProcedure.timestampentry">Timestampentry</Translate>
            </span>
          </dt>
          <dd>{hospitalProcedureEntity.timestampentry}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="tinyMedicRestApp.hospitalProcedure.active">Active</Translate>
            </span>
          </dt>
          <dd>{hospitalProcedureEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="lastupdated">
              <Translate contentKey="tinyMedicRestApp.hospitalProcedure.lastupdated">Lastupdated</Translate>
            </span>
          </dt>
          <dd>{hospitalProcedureEntity.lastupdated}</dd>
          <dt>
            <span id="proposedPrice">
              <Translate contentKey="tinyMedicRestApp.hospitalProcedure.proposedPrice">Proposed Price</Translate>
            </span>
          </dt>
          <dd>{hospitalProcedureEntity.proposedPrice}</dd>
          <dt>
            <span id="cursor">
              <Translate contentKey="tinyMedicRestApp.hospitalProcedure.cursor">Cursor</Translate>
            </span>
          </dt>
          <dd>{hospitalProcedureEntity.cursor}</dd>
          <dt>
            <span id="microSec">
              <Translate contentKey="tinyMedicRestApp.hospitalProcedure.microSec">Micro Sec</Translate>
            </span>
          </dt>
          <dd>{hospitalProcedureEntity.microSec}</dd>
          <dt>
            <span id="snomedConceptId">
              <Translate contentKey="tinyMedicRestApp.hospitalProcedure.snomedConceptId">Snomed Concept Id</Translate>
            </span>
          </dt>
          <dd>{hospitalProcedureEntity.snomedConceptId}</dd>
          <dt>
            <span id="snomedConceptText">
              <Translate contentKey="tinyMedicRestApp.hospitalProcedure.snomedConceptText">Snomed Concept Text</Translate>
            </span>
          </dt>
          <dd>{hospitalProcedureEntity.snomedConceptText}</dd>
          <dt>
            <span id="procedureType">
              <Translate contentKey="tinyMedicRestApp.hospitalProcedure.procedureType">Procedure Type</Translate>
            </span>
          </dt>
          <dd>{hospitalProcedureEntity.procedureType}</dd>
          <dt>
            <span id="procedureCategory">
              <Translate contentKey="tinyMedicRestApp.hospitalProcedure.procedureCategory">Procedure Category</Translate>
            </span>
          </dt>
          <dd>{hospitalProcedureEntity.procedureCategory}</dd>
          <dt>
            <span id="admissionRequired">
              <Translate contentKey="tinyMedicRestApp.hospitalProcedure.admissionRequired">Admission Required</Translate>
            </span>
          </dt>
          <dd>{hospitalProcedureEntity.admissionRequired ? 'true' : 'false'}</dd>
          <dt>
            <span id="prepareItemOrders">
              <Translate contentKey="tinyMedicRestApp.hospitalProcedure.prepareItemOrders">Prepare Item Orders</Translate>
            </span>
          </dt>
          <dd>{hospitalProcedureEntity.prepareItemOrders ? 'true' : 'false'}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.hospitalProcedure.procedure">Procedure</Translate>
          </dt>
          <dd>{hospitalProcedureEntity.procedure ? hospitalProcedureEntity.procedure.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.hospitalProcedure.hospital">Hospital</Translate>
          </dt>
          <dd>{hospitalProcedureEntity.hospital ? hospitalProcedureEntity.hospital.id : ''}</dd>
          <dt>
            <Translate contentKey="tinyMedicRestApp.hospitalProcedure.addedBy">Added By</Translate>
          </dt>
          <dd>{hospitalProcedureEntity.addedBy ? hospitalProcedureEntity.addedBy.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/hospital-procedure" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/hospital-procedure/${hospitalProcedureEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default HospitalProcedureDetail;
