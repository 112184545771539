import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import HospitalProcedureItem from './hospital-procedure-item';
import HospitalProcedureItemDetail from './hospital-procedure-item-detail';
import HospitalProcedureItemUpdate from './hospital-procedure-item-update';
import HospitalProcedureItemDeleteDialog from './hospital-procedure-item-delete-dialog';

const HospitalProcedureItemRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<HospitalProcedureItem />} />
    <Route path="new" element={<HospitalProcedureItemUpdate />} />
    <Route path=":id">
      <Route index element={<HospitalProcedureItemDetail />} />
      <Route path="edit" element={<HospitalProcedureItemUpdate />} />
      <Route path="delete" element={<HospitalProcedureItemDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default HospitalProcedureItemRoutes;
